import { BehaviorSubject } from 'rxjs';
import axios from "axios";

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser'));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(email, password) {
    return axios
        .post(
            process.env.REACT_APP_BACKEND_API_URL + "/api/login",

            {
                email: email,
                password: password
            },
            {withCredentials: true}
        )
        .then(response => {
            if (response.data === "error") {
                return("NOK");
            } else {
                const user = response.data;
                localStorage.setItem('currentUser', JSON.stringify(user));
                currentUserSubject.next(user);
                return user;
            }
        })
        .catch(error => {
            console.log("err", error);
            return ("ERR");
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    window.history.go("/");
}
