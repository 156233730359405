import React, { Component } from "react";
import axios from "axios";

import Registration from "./auth/Registration";
import Login from "./auth/Login";
import swal from "sweetalert";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideRegistration: true
        }

        this.showHideRegistration = this.showHideRegistration.bind(this);
        this.handleSuccessfulAuth = this.handleSuccessfulAuth.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
    }

    showHideRegistration = (isLoggingIn) => {
        this.setState({hideRegistration: isLoggingIn})
    }

    handleSuccessfulAuth(data) {
        if (data === "NOK") {
            swal("Oops", "Tu correo o contraseña son erróneos", "error");
        } else if (data === "ERR") {
            swal("Oops!", "Ha ocurrido un error", "error");
        } else {
            this.props.handleLogin(data);
            if (data.isAdmin || data.isSuperAdmin) {
                this.props.history.push("/dashboard");
            } else {
                this.props.history.push("/libros");
            }
        }
    }

    handleLogoutClick() {
        axios
            .delete("http://localhost:3001/logout", { withCredentials: true })
            .then(() => {
                this.props.handleLogout();
            })
            .catch(error => {
                console.log("logout error", error);
            });
    }

    render() {
        return (
            <div>
                {!this.state.hideRegistration && <Registration handleSuccessfulAuth={this.handleSuccessfulAuth} showHideRegistration={this.showHideRegistration}/>}
                {this.state.hideRegistration && <Login handleSuccessfulAuth={this.handleSuccessfulAuth} showHideRegistration={this.showHideRegistration} />}
            </div>
        );
    }
}
