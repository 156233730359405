import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./Home";
import Menu from "./dashboard/Menu"
import { ProtectedRoute } from "./ProtectedRoute";
import MenuUsuario from "./libros/MenuUsuario";

export default class App extends Component {
    constructor() {
        super();

        this.state = {
            loggedInStatus: "NOT_LOGGED_IN",
            user: {}
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        this.setState({
            loggedInStatus: "NOT_LOGGED_IN",
            user: {}
        });
    }

    handleLogin(data) {
        this.setState({
            loggedInStatus: "LOGGED_IN",
            user: data
        });
    }

    render() {
        return (
            <div className="app">
                <BrowserRouter>
                    <Switch>
                        <Route
                            exact
                            path={"/"}
                            render={props => (
                                <Home
                                    {...props}
                                    handleLogin={this.handleLogin}
                                    handleLogout={this.handleLogout}
                                    loggedInStatus={this.state.loggedInStatus}
                                />
                            )}
                        />
                        <ProtectedRoute
                            exact
                            path={"/dashboard"}
                            component={props => (
                                <Menu
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    user={this.state.user}
                                />
                            )}
                            isLoggedIn={this.state.loggedInStatus}
                        />
                        <ProtectedRoute
                            exact
                            path={"/libros"}
                            component={props => (
                                <MenuUsuario
                                    {...props}
                                    loggedInStatus={this.state.loggedInStatus}
                                    user={this.state.user}
                                />
                            )}
                            isLoggedIn={this.state.loggedInStatus}
                        />
                        <Route path="*" component={() => "404 NOT FOUND"} />
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}
