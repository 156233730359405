import React, { Component } from "react";
import axios from "axios";
import "../../style/Login.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import logo from "../../icons/icon.gif";
import Recaptcha from "react-recaptcha";
import swal from "sweetalert";

export default class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            password_confirmation: "",
            registrationErrors: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.reCaptchaLoaded= this.reCaptchaLoaded.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    reCaptchaLoaded() {

    }

    verifyCallback(response) {
        if (response)
            this.setState({
                isVerified: true
            });
    }

    handleSubmit(event) {
        const { email, password, password_confirmation, isVerified } = this.state;

        if (isVerified){
            axios
                .post(
                    process.env.REACT_APP_BACKEND_API_URL + "/api/register",
                    {
                        user: {
                            email: email,
                            password: password,
                            password_confirmation: password_confirmation
                        }
                    }
                )
                .then(response => {
                    console.log(response)
                    if (response.data === "created") {
                        swal("Éxito!", "Usuario creado exitosamente", "success");
                        this.props.handleSuccessfulAuth(response.data);
                    } else if (response.data === "already registered") {
                        swal("Oops!", "El correo que intentas utilizar ya se encuentra registrado", "warning");
                    } else {
                        swal("Oops!", "Ha ocurrido un error", "error");
                    }
                })
                .catch(error => {
                    swal("Oops!", "Ha ocurrido un error", "error");
                    console.log("registration error", error);
                });
            event.preventDefault();
        }
    }

    render() {
        return (
            <div className="wrapper fadeInDown">
                <div id="formContent">
                    <div className="fadeIn first back">
                        <button className="underlineHover" onClick={() => this.props.showHideRegistration(true)}>
                            <FontAwesomeIcon icon={faArrowLeft} size="3x"/>
                        </button>
                        <img src={logo} id="icon" alt="logo" />
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <input
                            type="email"
                            id="email"
                            className="fadeIn second"
                            name="email"
                            placeholder="Correo electr&oacute;nico"
                            value={this.state.user}
                            onChange={this.handleChange}
                            required
                        />
                        <input
                            type="password"
                            id="password"
                            className="fadeIn third"
                            name="password"
                            placeholder="Contrase&ntilde;a"
                            value={this.state.password}
                            onChange={this.handleChange}
                            required
                        />
                        <input
                            type="password"
                            id="passwordConfirmation"
                            className="fadeIn fourth"
                            name="password_confirmation"
                            placeholder="Confirma tu contrase&ntilde;a"
                            value={this.state.password_confirmation}
                            onChange={this.handleChange}
                            required
                        />
                        <div  className="fadeIn fifth captcha">
                            <Recaptcha
                                sitekey = "6LcsSqUZAAAAAIRW40plIhdN3eLmXs724Z_v1efm"
                                render = "explicit"
                                onloadCallback = {this.reCaptchaLoaded}
                                verifyCallback = {this.verifyCallback}
                            />
                        </div>
                        <input type="submit" className={"fadeIn sixth"} value="Registarse" />
                    </form>
                </div>
            </div>
        );
    }
}
