import React, {useEffect, forwardRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Copyright from "./Copyright";
import MaterialTable from 'material-table';
import axios from "axios";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import swal from "sweetalert";
import {authHeader} from "../auth/authHeader";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

let rows = [];

const cols = [
    { field: 'id', hidden: true},
    { title: 'Correo Electrónico', field: 'email' },
    { title: 'Usuario Deshabilitado', field: 'isDisabled' },
    { title: 'Administrador', field: 'isAdmin' },

];

function createTableData (id, email, isDisabled, isAdmin, isSuperAdmin) {
    return {id, email, isDisabled, isAdmin, isSuperAdmin}
}

export default function Users() {
    const classes = useStyles();
    const [users, setUsers] = useState(null);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const fetchData = async () => {
            try {
                 await axios
                .get(
                    process.env.REACT_APP_BACKEND_API_URL + "/api/getUsers",
                    {
                        cancelToken: source.token,
                        headers: authHeader()
                    }
                ).then(resp => {
                    setUsers(resp.data);
                }).catch(error => {
                     if (error.response.status === 401) {
                         window.history.go("/");
                     }
                });
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log("Cancelled")
                } else {
                    console.log(error.statusCode);
                }
            }
        };
        fetchData();
        return () => {
            source.cancel();
        }
    }, []);

    if(users!=null){
        rows=[];
        Object.keys(users).forEach(function(key) {
            rows.push(createTableData(users[key].id, users[key].email, users[key].isDisabled === "1" ? "1" : "0", users[key].isAdmin == "1" || users[key].isSuperAdmin == "1" ? "1" : "0"));
        });
    }

    const handleRowUpdate = (oldData, newData, resolve) => {
        const updateUser = async () => {
            try {
                await axios
                    .put(
                        process.env.REACT_APP_BACKEND_API_URL + "/api/updateUser",
                        {
                            id: oldData.id,
                            email: newData.email,
                            isAdmin: newData.isAdmin,
                            isDisabled: newData.isDisabled
                        },
                        {
                            headers: authHeader()
                        }
                    ).then(response => {
                            let dataUpdate = [...users];
                            let index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setUsers([...dataUpdate]);
                            resolve();
                            swal("Éxito!", "Usuario actualizado exitosamente", "success");
                        }
                    ).catch(error => {
                        console.log("error updating user", error);
                        swal("Error!", "No se ha podido actualizar al usuario", "error");
                        resolve();
                    });
            } catch (error) {
                console.log(error);
            }
        };
        updateUser();
    };

    const handleRowDelete = (oldData, resolve) => {
        const deleteUser = async () => {
            try {
                await axios
                    .delete(
                        process.env.REACT_APP_BACKEND_API_URL + "/api/deleteUser",
                        {
                            headers: authHeader(),
                            data: {
                                id: oldData.id
                            }
                        }
                    ).then(response => {
                        let dataDelete = [...users];
                        let index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setUsers([...dataDelete]);
                            resolve();
                            swal("Éxito!", "Usuario eliminado exitosamente", "success");
                        }
                    ).catch(error => {
                        console.log("error deleting user", error);
                        swal("Error!", "No se ha podido eliminar al usuario", "error");
                        resolve();
                    });
            } catch (error) {
                console.log(error);
            }
        };
        deleteUser();
    };

    return (
        <div className={classes.root}>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <MaterialTable
                        icons={tableIcons}
                        title="Usuarios"
                        columns={cols}
                        data={rows}
                        editable={{
                            isEditable: rowData => rowData.email !== 'deigo.goruzedri@gmail.com',
                            isEditHidden: rowData => rowData.email === 'deigo.goruzedri@gmaill.com',
                            isDeletable: rowData => rowData.email !== 'deigo.goruzedri@gmail.com',
                            isDeleteHidden: rowData => rowData.email === 'deigo.goruzedri@gmail.com',
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    handleRowUpdate(oldData,newData,resolve);
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve) => {
                                    handleRowDelete(oldData, resolve);
                                })
                        }}
                    />
                    <Box pt={4}>
                        <Copyright/>
                    </Box>
                </Container>
            </main>
        </div>
    );
}
