import React, {useEffect, forwardRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Copyright from "./Copyright";
import MaterialTable from 'material-table';
import axios from "axios";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {authHeader} from "../auth/authHeader";
import swal from "sweetalert";
import {OpenInNew} from "@material-ui/icons";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

let rows = [];

const cols = [
    { field: 'id', hidden: true},
    { title: 'Título', field: 'name' },
    { title: 'Autor', field: 'author' },
    { title: 'URL', field: 'bookUrl'}
];

function createTableData (id, name, author, bookUrl) {
    return {id, name, author, bookUrl}
}

export default function Books() {
    const classes = useStyles();
    const [books, setBooks] = React.useState(null);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const fetchData = async () => {
            try {
                await axios
                    .get(
                        process.env.REACT_APP_BACKEND_API_URL + "/api/getBooks",
                        {
                            cancelToken: source.token,
                            headers: authHeader()
                        }
                    ).then(resp => {
                        setBooks(resp.data);
                    }).catch(error => {
                        if (error.response.status === 401) {
                            window.history.go("/");
                        }
                    });
            } catch (error) {
                if (axios.isCancel(error)){
                    console.log("Cancelled")
                } else {
                    console.log(error);
                }
            }
        };
        fetchData();
        return () => {
            source.cancel();
        }
    }, []);

    if(books!=null){
        rows=[];
        Object.keys(books).forEach(function(key) {
            rows.push(createTableData(books[key].id, books[key].name, books[key].author, books[key].bookUrl));
        });
    }

    const handleRowUpdate = (oldData, newData, resolve) => {
        const updateBook = async () => {
            try {
                await axios
                    .put(
                        process.env.REACT_APP_BACKEND_API_URL + "/api/updateBook",
                        {
                            id: oldData.id,
                            name: newData.name,
                            author: newData.author,
                            bookUrl: newData.bookUrl
                        },
                        {
                            headers: authHeader()
                        }
                    ).then(response => {
                            let dataUpdate = [...books];
                            let index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setBooks([...dataUpdate]);
                            resolve();
                            swal("Éxito!", "Libro actualizado exitosamente", "success");
                        }
                    ).catch(error => {
                        console.log("error updating user", error);
                        swal("Error!", "No se ha podido actualizar el libro", "error");
                        resolve();
                    });
            } catch (error) {
                console.log(error);
            }
        };
        updateBook();
    };

    return (
        <div className={classes.root}>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <MaterialTable
                        icons={tableIcons}
                        title="Libros"
                        columns={cols}
                        data={rows}
                        editable={{
                            isDeletable: rowData => false,
                            isDeleteHidden: rowData => true,
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    handleRowUpdate(oldData,newData,resolve);
                                })
                        }}
                        actions={[
                            {
                                icon: OpenInNew,
                                tooltip: 'Ir al libro',
                                onClick: (event, rowData) => window.open(rowData.bookUrl, '_blank').focus()
                            }
                        ]}
                    />
                    <Box pt={4}>
                        <Copyright/>
                    </Box>
                </Container>
            </main>
        </div>
    );
}
