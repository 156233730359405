import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Copyright from "./Copyright";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import swal from "sweetalert";
import LoadingOverlay from 'react-loading-overlay';
import {authHeader} from "../auth/authHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    form: {
        margin: 11
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

let books =[];

export default function CodeGeneration() {
    const classes = useStyles();
    const [codesNumber, setCodesNumber] = React.useState(0);
    const [salePrice, setSalePrice] = React.useState(0);
    const [booksState, setBooksState] = React.useState(null);
    const [selectedBook, setSelectedBook] = React.useState("");
    const [isActive, setisActive] = React.useState(false);

    const handleSelectChange = (event) => {
        setSelectedBook(event.target.value);
    }

    const handleTextFieldCodesNumberChange = (event) => {
        setCodesNumber(parseInt(event.target.value));
    }

    const handleTextFieldSalePriceChange = (event) => {
        setSalePrice(parseInt(event.target.value));
    }

    const handleSubmit = () => {
        setisActive(true);
        //GenerateCodes
        axios
            .post(
                process.env.REACT_APP_BACKEND_API_URL + "/api/genCodes",
                {
                    codesToGenerate: codesNumber,
                    bookId: selectedBook,
                    salePrice: salePrice
                },
                {
                    headers: authHeader()
                }
            ).then(resp => {
                if (resp.data === "success") {
                    setisActive(false);
                    swal("¡Exito!", "Se han generado códigos exitosamente", "success");
                } else {
                    setisActive(false);
                    swal("Oops!", "Ha ocurrido un error", "error");
                }
            }).catch(err => {
                setisActive(false);
                if (err.response.status === 401) {
                    window.history.go("/");
                }
                console.log(err);
                swal("Oops!", "Ha ocurrido un error", "error");
            });
    }

    //GetBooks
    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const fetchData = async () => {
            try {
                await axios
                    .get(
                        process.env.REACT_APP_BACKEND_API_URL + "/api/getBooks",
                        {
                            cancelToken: source.token,
                            headers: authHeader()
                        }
                    ).then(resp => {
                        setBooksState(resp.data);
                    }).catch(error => {
                        if (error.response.status === 401) {
                            window.history.go("/");
                        }
                    });
            } catch (error) {
                if (axios.isCancel(error)){
                    console.log("Cancelled")
                } else {
                    console.log(error);
                }
            }
        };
        fetchData();
        return () => {
            source.cancel();
        }
    }, []);

    if(booksState!=null){
        books=[];
        books = booksState;
    }

    return (
        <div className={classes.root}>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Ejecutando... Puede tomar unos segundos'
            >
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Libros</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedBook}
                                onChange={handleSelectChange}
                                required
                            >
                                {books != null ? books.map((book, key) => {
                                   return <MenuItem key={key} value={book.id}>{book.name}</MenuItem>
                                }) : "No se pueden generar códigos en este momento"}
                            </Select>
                            <TextField id="codesNumber"
                                       label="Cantidad de códigos"
                                       variant="outlined"
                                       type="number"
                                       min="0"
                                       margin="dense"
                                       InputProps={{ inputProps: { min: 1 } }}
                                       onChange={handleTextFieldCodesNumberChange}
                                       required
                            />
                            <TextField id="salePrice"
                                       label="Precio de venta de código"
                                       variant="outlined"
                                       type="number"
                                       min="0"
                                       margin="dense"
                                       InputProps={{ inputProps: { min: 1 } }}
                                       onChange={handleTextFieldSalePriceChange}
                                       required
                            />
                            <Button onClick={handleSubmit} variant="contained" color="primary">Generar</Button>
                        </FormControl>
                        <Box pt={4}>
                            <Copyright/>
                        </Box>
                    </Container>
                </main>
            </LoadingOverlay>
        </div>
    );
}
