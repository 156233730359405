import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Copyright from "./Copyright";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import swal from "sweetalert";
import LoadingOverlay from 'react-loading-overlay';
import {authHeader} from "../auth/authHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    form: {
        margin: 11
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "inherit",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function AddBook() {
    const classes = useStyles();
    const [bookName, setBookName] = React.useState("");
    const [author, setBookAuthor] = React.useState("");
    const [url, setBookURL] = React.useState("");
    const [isActive, setisActive] = React.useState(false);

    const handleTextFieldBookName = (event) => {
        setBookName(event.target.value);
    }

    const handleTextFieldBookAuthor = (event) => {
        setBookAuthor(event.target.value);
    }

    const handleTextFieldBookURL = (event) => {
        setBookURL(event.target.value);
    }

    const handleSubmit = () => {
        setisActive(true);
        //SaveBook
        axios
            .post(
                process.env.REACT_APP_BACKEND_API_URL + "/api/addBook",
                {
                    name: bookName,
                    author: author,
                    url: url
                },
                {
                    headers: authHeader()
                }
            ).then(resp => {
            if (resp.data === "success") {
                setisActive(false);
                swal("¡Exito!", "Se ha agregado el libro exitosamente", "success");
            } else {
                setisActive(false);
                swal("Oops!", "Ha ocurrido un error", "error");
            }
        }).catch(err => {
            setisActive(false);
            if (err.response.status === 401) {
                window.history.go("/");
            }
            console.log(err);
            swal("Oops!", "Ha ocurrido un error", "error");
        });
    }

    return (
        <div className={classes.root}>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Ejecutando... Puede tomar unos segundos'
                styles={{
                    wrapper: { width: '100%' }
                }}
            >
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <FormControl className={classes.formControl}>
                            <TextField id="name"
                                       label="Título del libro"
                                       variant="outlined"
                                       type="text"
                                       margin="dense"
                                       onChange={handleTextFieldBookName}
                                       required
                            />
                            <TextField id="author"
                                       label="Autor del libro"
                                       variant="outlined"
                                       type="text"
                                       margin="dense"
                                       onChange={handleTextFieldBookAuthor}
                                       required
                            />
                            <TextField id="url"
                                       label="URL del libro"
                                       variant="outlined"
                                       type="text"
                                       margin="dense"
                                       onChange={handleTextFieldBookURL}
                                       required
                            />
                            <Button onClick={handleSubmit} variant="contained" color="primary">Agregar</Button>
                        </FormControl>
                        <Box pt={4}>
                            <Copyright/>
                        </Box>
                    </Container>
                </main>
            </LoadingOverlay>
        </div>
    );
}
