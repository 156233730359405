import React, { Component } from "react";
import logo from "../../icons/icon.gif";
import "../../style/Login.css"
import {authenticationService} from "./authenticationService";
export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        const { email, password } = this.state;
        authenticationService.login(email,password).then(data => {
            this.props.handleSuccessfulAuth(data);
        });
        event.preventDefault();
    }

    render() {
        return (
            <div className="wrapper fadeInDown">
                <div id="formContent">
                    <div className="fadeIn first">
                        <img src={logo} id="icon" alt="logo" />
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <input
                            type="email"
                            id="email"
                            className="fadeIn second"
                            name="email"
                            placeholder="Correo electrónico"
                            value={this.state.email}
                            onChange={this.handleChange}
                            required
                        />
                        <input
                            type="password"
                            id="password"
                            className="fadeIn third"
                            name="password"
                            placeholder="Contrase&ntilde;a"
                            value={this.state.password}
                            onChange={this.handleChange}
                            required
                        />
                        <input type="submit" className="fadeIn fourth" value="Iniciar Sesi&oacute;n"/>
                    </form>
                    <div id="formFooter">
                        {/*<button className="underlineHover">¿Olvidaste tu contrase&ntilde;a?</button>*/}
                        <br/>
                        <button className="underlineHover" onClick={() => this.props.showHideRegistration(false)}> ¿No tienes cuenta? ¡Reg&iacute;strate!</button>
                    </div>
                </div>
                <div className="footer">
                    <img src={process.env.PUBLIC_URL + '/icon.png' } alt="Mundo del Libro Editores"/>
                </div>
            </div>
        );
    }
}
